.countdownWrapper {
  margin-top: 60px;
  text-align: center;
  margin-bottom: 50px;
}

.modeNav {
  margin-bottom: 45px;
}

.controlButtons {
  padding-top: 45px;
}
