.doneWrapper {
  width: 100%;
  margin-bottom: 50px;
}

.doneTaskItem {
  border-left: 1px solid #cccccc !important;
  border-right: 1px solid #cccccc !important;
  background-color: white;
  padding-left: 15px;
  padding-right: 15px;
}

.clearListButtonCol {
  text-align: right;
}

.deleteCol {
  text-align: right;
}

.ant-list-header {
  border-bottom: 1px solid #f0f0f0;
  background-color: #001529;
  color: white;
  padding-bottom: 15px;
  padding-left: 15px;
  border-radius: 5px 5px 0px 0px;
}

.doneList {
  margin-top: 40px;
  border-bottom: 1px solid #cccccc !important;
  border-radius: 0px 0px 5px 5px;
}

.rowHeader {
  font-weight: bold;
}
