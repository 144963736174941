.todoWrapper {
  width: 100%;
  margin-bottom: 50px;
}

.newTaskButtonCol {
  text-align: "right";
}

.pendingTaskHeader {
  margin-bottom: 15px;
}
