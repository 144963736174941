@import "~antd/dist/antd.css";

.ant-menu {
  float: right;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.ant-layout-footer {
  background-color: white;
}

.ant-slider-rail {
  background-color: #d4f1f4 !important;
}

.tasksRow {
  margin-top: 50px;
  padding: 0px 20px 0px 20px;
}

.contentWrapper {
  margin-top: 60px;
}
