.pendingTask {
  border-left: 1px solid #cccccc !important;
  border-right: 1px solid #cccccc !important;
  background-color: white;
  padding-left: 15px;
  padding-right: 15px;
}

.pendingTask:last-child {
  border-bottom: 1px solid #cccccc !important;
}

.optionDropdownCol {
  text-align: right;
}

.pendingTaskOptions {
  float: right;
}
