.signInDropdown {
  float: right;
  padding-right: 24px;
}

.signOutDropdown {
  float: right;
  padding-right: 24px;
}

.logoutText {
  text-align: center;
}

.loginText {
  font-size: 16px;
}
