#timeText {
  font-size: 75px;
  color: #17365d;
  width: 100%;
}

.clockDisplayContainer {
  width: 100%;
  text-align: center;
  padding-top: 10;
}
