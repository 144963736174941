.logoText {
  height: 32px;
  margin: 16px;
  color: white;
  margin-left: 5px;
}

.logo {
  padding-left: 25px;
}

.clockIcon {
  height: 32px;
  font-size: 20px;
  color: white;
}

.menuParent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 94%;
}

#feedbackButton {
  background-color: white;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: black;
}

#supportButton {
  background-color: #001529;
  height: 50px;
  color: white;
  line-height: 50px;
  text-align: center;
}

.helpMenuLink {
  text-decoration: none;
}
