.overviewRow {
  padding-top: 24px;
  padding-bottom: 0px;
}

.activityRow {
  padding-bottom: 24px;
}

.gridContent {
  padding-left: 24px;
  padding-right: 24px;
}
